import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import Page from "../components/project_d.js"
import IndexSPA from "../components/IndexSPA"
import { Children } from "react";
import window from 'global'

// export default Page

const Index = (props) => {
  return (
    <>
      <Helmet
      title="社群擾動 / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫"
      meta={[
        {
          name: `description`,
          content: "社會住宅的公共藝術即將開始啟動，在各類藝術計畫啟動之前，我們期許能擴大各種想像以及討論，擾動藝文工作者對於參與社宅公共藝術的認知與期待，鋪陳日後與藝術家在實際實踐場域的互動與參與，打開社宅公共藝術的N+1種想像！",
        },
        {
          property: `og:title`,
          content: "社群擾動 / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫",
        },
        // {
        //   property: `og:url`,
        //   content: 'https://online.home-to-all.com' + window.location.pathname,
        // },
        {
          property: `og:description`,
          content: "社會住宅的公共藝術即將開始啟動，在各類藝術計畫啟動之前，我們期許能擴大各種想像以及討論，擾動藝文工作者對於參與社宅公共藝術的認知與期待，鋪陳日後與藝術家在實際實踐場域的互動與參與，打開社宅公共藝術的N+1種想像！",
        },
        {
          property: `og:image`,
          content: 'https://online.home-to-all.com' + require('../images/422socialimage.jpg')
        }
      ]}
      >
      </Helmet>
      <IndexSPA />
      {/* <Page /> */}
    </>
  )
 };
 
 // Index.propTypes = {}
 
 export default Index;

 